import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './DeleteConfirmationModal.scss'

export class ConfirmCancelOCRModal extends React.Component {
  static propTypes = {
    confirmationFunction: PropTypes.func,
    onClose: PropTypes.func,
    show: PropTypes.bool
  }

  render() {
    const { show } = this.props

    return (
      <div
        className={classNames(
          styles.modal,
          styles.templatesModal,
          !show ? styles.hidden : '',
          styles.maxZIndex
        )}
      >
        <div
          className={classNames(styles.modalOuter, styles.modalOuterSmall)}
          style={{ top: 'unset !important' }}
        >
          <div className={styles.modalInner}>
            <div className={styles.templates}>
              <div className={styles.templatesHeading}>
                <h2>Stop Scanning</h2>
                <div
                  className={styles.modalClose}
                  onClick={() => this.props.onClose()}
                >
                  <i className='material-icons'>close</i>
                </div>
              </div>
              <div className={styles.templatesInner}>
                <p>
                  Want to abort part of the entire scan? Choose an option below:
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <button
                  type='button'
                  className={classNames(styles.button, styles.buttonDelete)}
                  onClick={event => {
                    event.stopPropagation()
                    this.props.confirmationFunction('categorization')
                    this.props.onClose()
                  }}
                  style={{ width: '80%' }}
                >
                  Stop Categorization Scan
                </button>
                <button
                  type='button'
                  className={classNames(styles.button, styles.buttonDelete)}
                  onClick={event => {
                    event.stopPropagation()
                    this.props.confirmationFunction()
                    this.props.onClose()
                  }}
                  style={{ width: '80%' }}
                >
                  Stop Categorization & Nameplate Scan
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ConfirmCancelOCRModal
