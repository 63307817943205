import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
// import CheckSectionItem from '../CheckSectionItem'
import styles from './CollapseSection.scss'
import CheckSectionItem from '../CheckSectionItem'
import {
  ANALYSIS_RESULT_SECTION,
  DESIGN_SECTION,
  DETAILS_SECTION,
  FINANCIAL_MODELING_SECTION,
  INCENTIVE_SECTION,
  LOCATION_SECTION
} from 'static/measure-sections'
import classNames from 'classnames'

const defaultSections = [
  DETAILS_SECTION(),
  DESIGN_SECTION,
  INCENTIVE_SECTION,
  FINANCIAL_MODELING_SECTION,
  ANALYSIS_RESULT_SECTION,
  LOCATION_SECTION
]

class CollapseSection extends Component {
  static propTypes = {
    customLabels: PropTypes.array,
    isSelected: PropTypes.bool,
    addField: PropTypes.func,
    saveHeading: PropTypes.func
  }

  state = {
    openSections: []
  }

  toggleSection = sectionName => {
    const { openSections } = this.state
    let isSelected = openSections.includes(sectionName)
    let otherSections = [...openSections].filter(name => name !== sectionName)
    this.setState({
      openSections: isSelected ? otherSections : [...otherSections, sectionName]
    })
  }

  renderFields = (options = [], sectionName) => {
    const {
      customLabels,
      addField,
      saveHeading,
      fields,
      allowCustomLabels
    } = this.props
    if (options.length === 0) return null
    return (
      <div className={styles.checkboxContainer}>
        {options.map((option, index) => {
          let label = option.name
          if (customLabels && customLabels.length > 0) {
            const customLabel = _.find(customLabels, { field: option.value })
            if (customLabel) label = customLabel.value
          }
          let isChecked = fields.includes(option.value)
          return (
            <CheckSectionItem
              item={option}
              toggleField={e => addField(e, sectionName)}
              saveHeading={saveHeading}
              customLabel={label}
              key={`${option.name} ${index}`}
              isChecked={isChecked}
              allowCustomLabels={allowCustomLabels}
            />
          )
        })}
      </div>
    )
  }

  renderSection = (options = []) => {
    const { openSections } = this.state
    const { sectionStyle } = this.props
    let sectionWithSubSections = options.filter(option => {
      let items = option.sections || []
      return items.length !== 0
    })
    let sectionFields = options.filter(option => {
      let items = option.sections || []
      return items.length === 0
    })
    if (options.length === 0) return null
    return (
      <div>
        {sectionWithSubSections.map((option, index) => {
          let sections = option.sections || []
          let isOpen = openSections.includes(option.sectionName)
          return (
            <div
              key={`${option.name} ${option.sectionName}`}
              className={classNames(styles.section, sectionStyle)}
            >
              <div className={styles.sectionHeader}>
                <div>{option.name}</div>
                <div
                  className={styles.sectionArrow}
                  onClick={() => this.toggleSection(option.sectionName)}
                >
                  {isOpen ? (
                    <i className='material-icons'>arrow_drop_up</i>
                  ) : (
                    <i className='material-icons'>arrow_drop_down</i>
                  )}
                </div>
              </div>
              {isOpen && this.renderSections(sections, option.sectionName)}
            </div>
          )
        })}
        {this.renderFields(sectionFields)}
      </div>
    )
  }

  renderSections = (options = [], sectionName) => {
    let sectionWithSubSections = options.filter(option => {
      let items = option.sections || []
      return items.length !== 0
    })
    let sectionFields = options.filter(option => {
      let items = option.sections || []
      return items.length === 0
    })
    if (options.length === 0) return null
    return (
      <div>
        {this.renderSection(sectionWithSubSections)}
        {this.renderFields(sectionFields, sectionName)}
      </div>
    )
  }

  render() {
    const {
      sections = defaultSections,
      containerStyle = '',
      sectionStyle = ''
    } = this.props
    return (
      <div className={classNames(styles.sectionContainer, containerStyle)}>
        {this.renderSections(sections)}
      </div>
    )
  }
}
export default CollapseSection
