import React, { useCallback } from 'react'
import ProjectContentItem from './ProjectContentItem'
import styles from './ProjectTableContentItem.scss'
import {
  ANALYSIS_RESULT_SECTION,
  COST_TABLE_FINANCIAL_MODELING_SECTION,
  DESIGN_SECTION,
  DETAILS_SECTION,
  EQUIPMENT_COST_TABLE_FINANCIAL_MODELING_SECTION,
  EQUIPMENT_FINANCIAL_MODELING_SECTION,
  FINANCIAL_MODELING_SECTION,
  INCENTIVE_SECTION,
  LOCATION_SECTION,
  MEASURE_CONTENT_OPTIONS,
  MEASURE_CONTENT_TABLE_TYPE
} from 'static/measure-sections'
import classNames from 'classnames'
import CollapseSection from 'components/UI/CollapseSection'
import { formatProjectFields } from 'utils/Utils'
import { set, get } from 'lodash'

const tableType = [
  {
    name: 'All Fields - Headings on Top',
    value: MEASURE_CONTENT_OPTIONS.PROJECT_DESIGN_TABLE
  },
  {
    name: 'All Fields - Headings to Left',
    value: MEASURE_CONTENT_OPTIONS.BUSINESS_CASE_TABLE
  },
  {
    name: 'Cost Table',
    value: MEASURE_CONTENT_OPTIONS.COST_TABLE
  },
  {
    name: 'Equipment Rows',
    value: MEASURE_CONTENT_OPTIONS.EQUIPMENT_LIST_TABLE
  }
]

const subTableType = [
  { name: 'All Fields', value: MEASURE_CONTENT_TABLE_TYPE.ALL_FIELDS },
  { name: 'Cost Table', value: MEASURE_CONTENT_TABLE_TYPE.COST_TABLE },
  {
    name: 'Existing vs Proposed',
    value: MEASURE_CONTENT_TABLE_TYPE.EXISTING_PROPOSED
  }
]

const orderFields = [
  { name: 'A-Z (Low to high)', value: 'ascending' },
  { name: 'Z-A (High to low)', value: 'descending' }
]

const allowEmptyFields = [
  { name: 'Show fields regardless if there are values', value: 'show' },
  { name: 'Hide fields without values', value: 'hide' }
]

const includeReduction = [
  { name: 'Include', value: 'include' },
  { name: 'Do not include', value: 'notInclude' }
]

const includeTotal = [
  { name: 'Include', value: 'include' },
  { name: 'Do not include', value: 'notInclude' }
]

const headingStyle = [
  { name: 'Heading 1', value: 'h1' },
  { name: 'Heading 2', value: 'h2' },
  { name: 'Heading 3', value: 'h3' },
  { name: 'Heading 4', value: 'h4' },
  { name: 'Heading 5', value: 'h5' },
  { name: 'Heading 6', value: 'h6' }
]

const getTableFieldsLabel = content => {
  const labels = content.fields?.map(item => {
    if (content.customLabels?.length > 0) {
      const customLabel = content.customLabels.find(
        ({ field }) => field === item
      )
      if (customLabel) return customLabel.value
    }
    return formatProjectFields(item)
  })
  return labels?.length && labels.join(', ').trim()
}

const getSubTitleForTableType = type => {
  switch (type) {
    case MEASURE_CONTENT_OPTIONS.BUSINESS_CASE_TABLE:
    case MEASURE_CONTENT_OPTIONS.PROJECT_DESIGN_TABLE:
      return 'All Fields'
    case MEASURE_CONTENT_OPTIONS.COST_TABLE:
      return 'Cost Table'
    case MEASURE_CONTENT_OPTIONS.EQUIPMENT_LIST_TABLE:
      return 'Equipment Rows'
    default:
      return 'All Fields'
  }
}

const getFieldSectionForTableType = (type, subType) => {
  switch (type) {
    case MEASURE_CONTENT_OPTIONS.BUSINESS_CASE_TABLE:
    case MEASURE_CONTENT_OPTIONS.PROJECT_DESIGN_TABLE:
      return [
        DETAILS_SECTION(),
        DESIGN_SECTION,
        INCENTIVE_SECTION,
        FINANCIAL_MODELING_SECTION,
        ANALYSIS_RESULT_SECTION,
        LOCATION_SECTION
      ]
    case MEASURE_CONTENT_OPTIONS.COST_TABLE:
      return [INCENTIVE_SECTION, COST_TABLE_FINANCIAL_MODELING_SECTION]
    case MEASURE_CONTENT_OPTIONS.EQUIPMENT_LIST_TABLE: {
      switch (subType) {
        case MEASURE_CONTENT_TABLE_TYPE.COST_TABLE:
          return [
            ...LOCATION_SECTION.sections,
            ...EQUIPMENT_COST_TABLE_FINANCIAL_MODELING_SECTION.sections
          ]
        case MEASURE_CONTENT_TABLE_TYPE.EXISTING_PROPOSED:
          return [...LOCATION_SECTION.sections]
        case MEASURE_CONTENT_TABLE_TYPE.ALL_FIELDS:
        default:
          return [
            DESIGN_SECTION,
            LOCATION_SECTION,
            EQUIPMENT_FINANCIAL_MODELING_SECTION
          ]
      }
    }
    default:
      return [
        DESIGN_SECTION,
        LOCATION_SECTION,
        EQUIPMENT_FINANCIAL_MODELING_SECTION
      ]
  }
}

const getTitle = content => {
  return content?.title || ''
}

const getSubTitle = content => {
  const fieldLabel = getTableFieldsLabel(content)
  return ` ${getSubTitleForTableType(content.key)} ${
    fieldLabel ? `| ${fieldLabel}` : ''
  }`
}

const getOrderByFields = (sections = [], selectedField) => {
  return sections.reduce((agg, item) => {
    if (item.sections) {
      agg = agg.concat(getOrderByFields(item.sections, selectedField))
    } else {
      if (selectedField?.indexOf(item.value) > -1) {
        agg.push(item)
      }
    }
    return agg
  }, [])
}

const ProjectTableContentItem = ({
  projectConfig,
  onRemove,
  index,
  onUpdateProjectConfig
}) => {
  const content = projectConfig.content[index]
  const onSaveSelectFields = useCallback(
    (value, type) => {
      set(content, type, value)
      projectConfig.content[index] = content
      onUpdateProjectConfig(projectConfig)
    },
    [onUpdateProjectConfig, content, projectConfig, index]
  )

  const selectFieldsFormat = useCallback(
    (array, type, selectTitle) => {
      let selectValue = get(content, type, 'select')
      if (type === 'key' && selectValue === MEASURE_CONTENT_OPTIONS.TABLE)
        selectValue = 'select'
      const result = type
        .split('.')
        .pop()
        .replace(/([A-Z])/g, ' $1')
      const title =
        selectTitle || result.charAt(0).toUpperCase() + result.slice(1)
      return (
        <select
          value={selectValue}
          onChange={e => onSaveSelectFields(e.target.value, type)}
        >
          <option disabled key='select_option' value='select'>
            {`Select ${title}`}
          </option>
          {array.map((item, i) => {
            return (
              <option key={i} value={item.value}>
                {item.name}{' '}
              </option>
            )
          })}
        </select>
      )
    },
    [content]
  )

  const saveCheckboxFields = useCallback(
    event => {
      const value = event.target.value
      const filteredFields =
        content.fields?.filter(item => item !== value) || []
      if (event.target.checked) {
        content.fields = [...filteredFields, value]
      } else {
        content.fields = [...filteredFields]
      }
      projectConfig.content[index] = content
      onUpdateProjectConfig(projectConfig)
    },
    [onUpdateProjectConfig, content, projectConfig, index]
  )

  const saveHeading = useCallback(
    (selectedField, value = '') => {
      let isFound = false
      const updatedLabels =
        content.customLabels?.map(item => {
          if (item.field === selectedField.value) {
            isFound = true
            return {
              ...item,
              value
            }
          }
          return item
        }) || []
      if (!isFound) {
        updatedLabels.push({
          field: selectedField.value,
          value
        })
      }
      content.customLabels = updatedLabels
      projectConfig.content[index] = content
      onUpdateProjectConfig(projectConfig)
    },
    [onUpdateProjectConfig, content, projectConfig, index]
  )

  const sections = getFieldSectionForTableType(
    content.key,
    content.config?.tableType
  )

  const clearDataFields = useCallback(
    event => {
      event.stopPropagation()
      event.preventDefault()
      content.fields = []
      content.customLabels = []
      projectConfig.content[index] = content
      onUpdateProjectConfig(projectConfig)
    },
    [onUpdateProjectConfig, content, projectConfig, index]
  )
  const loadExistingProposedSection = useCallback(() => {
    if (
      content.config?.tableType !== MEASURE_CONTENT_TABLE_TYPE.EXISTING_PROPOSED
    )
      return
    return (
      <>
        <h3>Reduction Row</h3>
        <div className={styles.selectContainer}>
          {selectFieldsFormat(includeReduction, 'config.includeReduction')}
        </div>
        <h3>Heading Style</h3>
        <div className={styles.selectContainer}>
          {selectFieldsFormat(headingStyle, 'config.headingStyle')}
        </div>
      </>
    )
  }, [content])

  const loadCostTableSection = useCallback(() => {
    if (
      content.key !== MEASURE_CONTENT_OPTIONS.COST_TABLE &&
      content.config?.tableType !== MEASURE_CONTENT_TABLE_TYPE.COST_TABLE
    )
      return
    return (
      <>
        <h3>Totals</h3>
        <div className={styles.selectContainer}>
          {selectFieldsFormat(includeTotal, 'config.includeTotal')}
        </div>
      </>
    )
  }, [content])

  const loadOrderBySection = useCallback(() => {
    if (content.key === MEASURE_CONTENT_OPTIONS.BUSINESS_CASE_TABLE) return
    const selectedOrderFields = getOrderByFields(sections, content.fields)
    return (
      <>
        {selectedOrderFields.length > 0 && (
          <>
            <h3>Order By</h3>
            <div className={styles.selectContainer}>
              {selectFieldsFormat(selectedOrderFields, 'config.orderBy')}
            </div>
          </>
        )}
        <h3>Order</h3>
        <div className={styles.selectContainer}>
          {selectFieldsFormat(orderFields, 'config.order')}
        </div>
      </>
    )
  }, [content, sections])

  return (
    <ProjectContentItem
      isCollapsible
      isRemovable
      renderHeaderSection={() => (
        <>
          <span className={styles.title}>
            {content.config?.title || getTitle(content)}
          </span>
          <span className={styles.subTitle}>
            {' | '}
            {getSubTitle(content)}
          </span>
        </>
      )}
      renderCollapsibleSection={() => (
        <div className={styles.collapsibleContainer}>
          <h3>Table Type</h3>
          <div className={styles.selectContainer}>
            {selectFieldsFormat(tableType, 'key', 'Table Type')}
          </div>
          {content.key === MEASURE_CONTENT_OPTIONS.EQUIPMENT_LIST_TABLE && (
            <>
              <h3>Sub Table Type</h3>
              <div className={styles.selectContainer}>
                {selectFieldsFormat(
                  subTableType,
                  'config.tableType',
                  'Sub Table Type'
                )}
              </div>
            </>
          )}
          <h3>Title</h3>
          <div className={styles.titleContainer}>
            <div className={styles.checkboxContainer}>
              <label>
                <input
                  checked={content.config?.includeTitle}
                  value='hasTitle'
                  name={'Content'}
                  className={classNames(
                    content.config?.includeTitle ? styles['checked'] : ''
                  )}
                  onChange={() =>
                    onSaveSelectFields(
                      !content.config?.includeTitle,
                      'config.includeTitle'
                    )
                  }
                  type='checkbox'
                />
                <span />
              </label>
            </div>
            <input
              type='text'
              placeholder='Enter Title'
              value={content.config?.title || getTitle(content)}
              onChange={e => onSaveSelectFields(e.target.value, 'config.title')}
            />
          </div>
          {content.key && content.key != MEASURE_CONTENT_OPTIONS.TABLE && (
            <>
              <div className={styles.fieldSelector}>
                <h3>Fields</h3>
                <div className={styles.fieldSelectorDescription}>
                  Select the fields for your table in the order they should
                  appear.
                </div>
              </div>

              <div className={styles.fieldContainer}>
                <div className={styles.fieldContainerItem}>
                  <div className={styles.fieldContainerTitle}>
                    Order: {getTableFieldsLabel(content) || ''}
                  </div>
                </div>
                <div
                  className={styles.fieldContainerClear}
                  onClick={clearDataFields}
                >
                  Clear Fields
                </div>
              </div>
              <div
                className={classNames(
                  styles.projectCheckboxContainer,
                  styles.businessContainer
                )}
              >
                <CollapseSection
                  customLabels={content.customLabels}
                  addField={saveCheckboxFields}
                  saveHeading={saveHeading}
                  fields={content.fields || []}
                  sections={sections}
                  containerStyle={styles.collapsibleContainerSection}
                  sectionStyle={styles.collapsibleSection}
                />
                <div className={classNames(styles.fullDetailContainer)}>
                  {loadOrderBySection()}
                  {loadCostTableSection()}
                  {loadExistingProposedSection()}
                  <h3>Show/Hide Fields</h3>
                  <div className={styles.selectContainer}>
                    {selectFieldsFormat(
                      allowEmptyFields,
                      'config.allowEmptyFields'
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
      handleContentRemoved={onRemove}
    />
  )
}

export default ProjectTableContentItem
