// MEASURES

export const MEASURE_STATUSES = {
  IDENTIFIED: 'Identified',
  RECOMMENDED: 'Recommended',
  EVALUATED: 'Evaluated',
  NOT_RECOMMENDED: 'Not Recommended',
  SELECTED: 'Selected',
  IN_PROGRESS: 'In Progress',
  ON_HOLD: 'On Hold',
  COMPLETED: 'Completed',
  'M&V': 'M&V',
  VERIFIED: 'Verified',
  UNSATISFACTORY: 'Unsatisfactory',
  CANCELLED: 'Cancelled',
  PROPOSED: 'Proposed',
  UNDER_REVIEW: 'Under Review',
  REVIEWED_AND_APPROVED: 'Reviewed and Approved',
  REVIEWED_AND_NOT_APPROVED: 'Reviewed and Not Approved',
  INTERIM_APPROVAL: 'Interim Approval',
  PLANNED_TO_BE_IMPLEMENTED: 'Planned to be Implemented',
  IMPLEMENTED: 'Implemented'
}

export const DEFAULT_MEASURE_STATUS = MEASURE_STATUSES.IDENTIFIED

export const IDENTIFIED_MEASURE_STATUSES = [
  MEASURE_STATUSES.IDENTIFIED,
  MEASURE_STATUSES.NOT_RECOMMENDED,
  MEASURE_STATUSES.RECOMMENDED,
  MEASURE_STATUSES.EVALUATED,
  MEASURE_STATUSES.SELECTED,
  MEASURE_STATUSES.PROPOSED,
  MEASURE_STATUSES.UNDER_REVIEW,
  MEASURE_STATUSES.REVIEWED_AND_APPROVED,
  MEASURE_STATUSES.REVIEWED_AND_NOT_APPROVED,
  MEASURE_STATUSES.INTERIM_APPROVAL
]

export const IN_PROGRESS_MEASURE_STATUSES = [
  MEASURE_STATUSES.IN_PROGRESS,
  MEASURE_STATUSES.ON_HOLD,
  MEASURE_STATUSES.CANCELLED,
  MEASURE_STATUSES['M&V'],
  MEASURE_STATUSES.VERIFIED,
  MEASURE_STATUSES.PLANNED_TO_BE_IMPLEMENTED,
  MEASURE_STATUSES.IMPLEMENTED
]

export const COMPLETED_MEASURE_STATUSES = [
  MEASURE_STATUSES.COMPLETED,
  MEASURE_STATUSES.UNSATISFACTORY
]

export const reduceMeasureStatuses = [
  { label: 'Identified', value: MEASURE_STATUSES.IDENTIFIED },
  { label: 'Recommended', value: MEASURE_STATUSES.RECOMMENDED },
  { label: 'Evaluated', value: MEASURE_STATUSES.EVALUATED }
]

export const measureStatuses = [
  { label: 'Identified', value: MEASURE_STATUSES.IDENTIFIED },
  { label: 'Not Recommended', value: MEASURE_STATUSES.NOT_RECOMMENDED },
  { label: 'Recommended', value: MEASURE_STATUSES.RECOMMENDED },
  { label: 'Evaluated', value: MEASURE_STATUSES.EVALUATED },
  { label: 'Selected', value: MEASURE_STATUSES.SELECTED },
  { label: 'Proposed', value: MEASURE_STATUSES.PROPOSED },
  { label: 'Under Review', value: MEASURE_STATUSES.UNDER_REVIEW },
  {
    label: 'Reviewed and Approved',
    value: MEASURE_STATUSES.REVIEWED_AND_APPROVED
  },
  {
    label: 'Reviewed and Not Approved',
    value: MEASURE_STATUSES.REVIEWED_AND_NOT_APPROVED
  },
  { label: 'Interim Approval', value: MEASURE_STATUSES.INTERIM_APPROVAL },
  {
    label: 'Planned to be Implemented',
    value: MEASURE_STATUSES.PLANNED_TO_BE_IMPLEMENTED
  },
  { label: 'In Progress', value: MEASURE_STATUSES.IN_PROGRESS },
  { label: 'On Hold', value: MEASURE_STATUSES.ON_HOLD },
  { label: 'Cancelled', value: MEASURE_STATUSES.CANCELLED },
  { label: 'Implemented', value: MEASURE_STATUSES.IMPLEMENTED },
  { label: 'M&V', value: MEASURE_STATUSES['M&V'] },
  { label: 'Verified', value: MEASURE_STATUSES.VERIFIED },
  { label: 'Completed', value: MEASURE_STATUSES.COMPLETED },
  { label: 'Unsatisfactory', value: MEASURE_STATUSES.UNSATISFACTORY }
]

export const measurePriorities = [
  { label: 'Select', value: '' },
  { label: 'Primary', value: 'primary' },
  { label: 'Secondary', value: 'secondary' },
  { label: 'Future Consideration', value: 'futureConsideration' },
  { label: 'Completed', value: 'completed' }
]

// PROJECTS

export const PROJECTS_STATUSES = {
  IDENTIFIED: 'Identified',
  RECOMMENDED: 'Recommended',
  EVALUATED: 'Evaluated',
  NOT_RECOMMENDED: 'Not Recommended',
  SELECTED: 'Selected',
  IN_PROGRESS: 'In Progress',
  ON_HOLD: 'On Hold',
  COMPLETED: 'Completed',
  'M&V': 'M&V',
  VERIFIED: 'Verified',
  UNSATISFACTORY: 'Unsatisfactory',
  CANCELLED: 'Cancelled'
}

export const DEFAULT_PROJECT_STATUS = PROJECTS_STATUSES.SELECTED

export const projectStatuses = [
  { label: 'Selected', value: PROJECTS_STATUSES.SELECTED },
  { label: 'In Progress', value: PROJECTS_STATUSES.IN_PROGRESS },
  { label: 'On Hold', value: PROJECTS_STATUSES.ON_HOLD },
  { label: 'Cancelled', value: PROJECTS_STATUSES.CANCELLED },
  { label: 'M&V', value: PROJECTS_STATUSES['M&V'] },
  { label: 'Verified', value: PROJECTS_STATUSES.VERIFIED },
  { label: 'Completed', value: PROJECTS_STATUSES.COMPLETED },
  { label: 'Unsatisfactory', value: PROJECTS_STATUSES.UNSATISFACTORY }
]

// PROPOSALS

export const PROPOSAL_STATUSES = {
  INITIAL_DRAFT: 'Initial Draft',
  READY_FOR_ESTIMATION: 'Ready for Estimation',
  ESTIMATING: 'Estimating',
  FINAL_DRAFT: 'Final Draft',
  CUSTOMER_REVIEW: 'Customer Review',
  REVISION_REQUIRED: 'Revision Required',
  DISCARDED: 'Discarded',
  ON_HOLD: 'On Hold',
  ACCEPTED: 'Accepted'
}

export const DEFAULT_PROPOSAL_STATUS = PROPOSAL_STATUSES.INITIAL_DRAFT

export const proposalsStatuses = [
  { label: 'Initial Draft', value: PROPOSAL_STATUSES.INITIAL_DRAFT },
  {
    label: 'Ready for Estimation',
    value: PROPOSAL_STATUSES.READY_FOR_ESTIMATION
  },
  { label: 'Estimating', value: PROPOSAL_STATUSES.ESTIMATING },
  { label: 'Final Draft', value: PROPOSAL_STATUSES.FINAL_DRAFT },
  { label: 'Customer Review', value: PROPOSAL_STATUSES.CUSTOMER_REVIEW },
  { label: 'Revision Required', value: PROPOSAL_STATUSES.REVISION_REQUIRED },
  { label: 'Discarded', value: PROPOSAL_STATUSES.DISCARDED },
  { label: 'On Hold', value: PROPOSAL_STATUSES.ON_HOLD },
  { label: 'Accepted', value: PROPOSAL_STATUSES.ACCEPTED }
]
