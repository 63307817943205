import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'

import {
  bodyTemplate,
  templateUpdateError
} from 'routes/Template/modules/template'

import styles from '../Projects.scss'
import portfolioStyles from '../../../../Portfolio/PortfolioContainer.scss'
import {
  ANALYSIS_RESULT_SECTION,
  DESIGN_SECTION,
  DETAILS_SECTION,
  FINANCIAL_MODELING_SECTION,
  INCENTIVE_SECTION,
  LOCATION_SECTION,
  MEASURE_CONTENT_OPTIONS
} from 'static/measure-sections'
import ProjectOrdering from '../ProjectOrdering'
import { uniq } from 'lodash'
import {
  ProjectCategoryContentItem,
  ProjectDescriptionContentItem,
  ProjectNameContentItem,
  ProjectImageContentItem,
  ProjectTableContentItem
} from './ProjectContentItem'

const orderFields = [
  { name: 'A-Z (Low to high)', value: 'ascending' },
  { name: 'Z-A (High to low)', value: 'descending' }
]

const orderFormatList = [
  { name: 'Order By Table Value', value: 'table_value' },
  { name: 'Order By Categorization', value: 'categorization' }
]

const contentList = [
  {
    label: 'Category Headings',
    value: MEASURE_CONTENT_OPTIONS.CATEGORY_HEADINGS
  },
  { label: 'Description', value: MEASURE_CONTENT_OPTIONS.DESCRIPTION },
  { label: 'Images', value: MEASURE_CONTENT_OPTIONS.IMAGES },
  { label: 'Table', value: MEASURE_CONTENT_OPTIONS.TABLE }
]

const tableContentOptions = [
  MEASURE_CONTENT_OPTIONS.TABLE,
  MEASURE_CONTENT_OPTIONS.BUSINESS_CASE_TABLE,
  MEASURE_CONTENT_OPTIONS.PROJECT_DESIGN_TABLE,
  MEASURE_CONTENT_OPTIONS.COST_TABLE,
  MEASURE_CONTENT_OPTIONS.EQUIPMENT_LIST_TABLE
]

const getAvailableContentList = existingContent => {
  return contentList.filter(
    ({ value }) =>
      !existingContent.find(
        ({ key }) => key !== MEASURE_CONTENT_OPTIONS.TABLE && key === value
      )
  )
}

const hasContent = (contentList, items) => {
  return contentList.some(({ key }) => items.some(value => key === value))
}

export class ProjectContent extends Component {
  static propTypes = {
    bodyTemplate: PropTypes.func.isRequired,
    templateUpdateError: PropTypes.func.isRequired,
    body: PropTypes.array.isRequired,
    index: PropTypes.number.isRequired,
    filteringFields: PropTypes.object.isRequired
  }

  state = {
    selectedField: null,
    customHeading: '',
    selectedType: '',
    node: null,
    showAddContent: false
  }
  componentDidMount() {
    let body = JSON.parse(JSON.stringify(this.props.body))
    let widget = body[this.props.index]
    if (!widget.tableLayout) {
      widget.tableLayout = 'horizontal'
    }
    this.props.bodyTemplate(body)
    this.props.templateUpdateError()
  }

  renderContentList = projectConfig => {
    return projectConfig.styles.layout === 'twoColumn'
      ? this.renderTwoColumnLayout(projectConfig)
      : this.renderSingleColumnLayout(projectConfig)
  }

  renderContentSectionHeading = projectConfig => {
    return (
      <div className={styles.contentSectionHeading}>
        {this.renderContentItem(projectConfig, [
          MEASURE_CONTENT_OPTIONS.CATEGORY_HEADINGS
        ])}
        <ProjectNameContentItem
          projectConfig={projectConfig}
          onUpdateProjectConfig={this.handleUpdateProjectConfig}
        />
      </div>
    )
  }

  renderSingleColumnLayout = projectConfig => {
    const items = [
      MEASURE_CONTENT_OPTIONS.DESCRIPTION,
      MEASURE_CONTENT_OPTIONS.IMAGES,
      ...tableContentOptions
    ]
    return (
      <div className={styles.singleColumn}>
        {this.renderContentSectionHeading(projectConfig)}
        <div className={styles.subContainer}>
          {hasContent(projectConfig.content, items) ? (
            this.renderContentItem(projectConfig, items)
          ) : (
            <span>Add Components to the Layout</span>
          )}
        </div>
      </div>
    )
  }

  renderTwoColumnLayout = projectConfig => {
    return (
      <div className={styles.twoColumn}>
        {this.renderContentSectionHeading(projectConfig)}
        <div className={styles.subContainer}>
          {hasContent(projectConfig.content, [
            MEASURE_CONTENT_OPTIONS.IMAGES
          ]) ? (
            this.renderContentItem(projectConfig, [
              MEASURE_CONTENT_OPTIONS.IMAGES
            ])
          ) : (
            <span>Add Images</span>
          )}
        </div>
        <div className={styles.subContainer}>
          {hasContent(projectConfig.content, [
            MEASURE_CONTENT_OPTIONS.DESCRIPTION
          ]) ? (
            this.renderContentItem(projectConfig, [
              MEASURE_CONTENT_OPTIONS.DESCRIPTION
            ])
          ) : (
            <span>Add a Description</span>
          )}
        </div>
        <div className={styles.subContainer}>
          {hasContent(projectConfig.content, tableContentOptions) ? (
            this.renderContentItem(projectConfig, tableContentOptions)
          ) : (
            <span>Add Tables</span>
          )}
        </div>
      </div>
    )
  }

  handleContentRemoved = (projectConfig, index) => () => {
    const body = JSON.parse(JSON.stringify(this.props.body))
    projectConfig.content.splice(index, 1)
    body[this.props.index].projectConfig = {
      ...projectConfig
    }
    this.props.bodyTemplate(body)
    this.props.templateUpdateError()
  }

  handleUpdateProjectConfig = updatedProjectConfig => {
    const body = JSON.parse(JSON.stringify(this.props.body))
    body[this.props.index].projectConfig = {
      ...updatedProjectConfig
    }
    this.props.bodyTemplate(body)
    this.props.templateUpdateError()
  }

  renderContentItem = (projectConfig, filterOnly = []) => {
    return projectConfig.content.map((item, index) => {
      if (!filterOnly.includes(item.key)) return
      switch (item.key) {
        case MEASURE_CONTENT_OPTIONS.DESCRIPTION:
          return (
            <ProjectDescriptionContentItem
              onRemove={this.handleContentRemoved(projectConfig, index)}
            />
          )
        case MEASURE_CONTENT_OPTIONS.NAME:
          return (
            <ProjectNameContentItem
              projectConfig={projectConfig}
              index={index}
              onUpdateProjectConfig={this.handleUpdateProjectConfig}
            />
          )
        case MEASURE_CONTENT_OPTIONS.CATEGORY_HEADINGS:
          return (
            <ProjectCategoryContentItem
              projectConfig={projectConfig}
              projectIndex={this.props.index}
              index={index}
              onRemove={this.handleContentRemoved(projectConfig, index)}
              onUpdateProjectConfig={this.handleUpdateProjectConfig}
            />
          )
        case MEASURE_CONTENT_OPTIONS.IMAGES:
          return (
            <ProjectImageContentItem
              projectConfig={projectConfig}
              index={index}
              onRemove={this.handleContentRemoved(projectConfig, index)}
              onUpdateProjectConfig={this.handleUpdateProjectConfig}
            />
          )
        case MEASURE_CONTENT_OPTIONS.TABLE:
        case MEASURE_CONTENT_OPTIONS.BUSINESS_CASE_TABLE:
        case MEASURE_CONTENT_OPTIONS.PROJECT_DESIGN_TABLE:
        case MEASURE_CONTENT_OPTIONS.COST_TABLE:
        case MEASURE_CONTENT_OPTIONS.EQUIPMENT_LIST_TABLE:
          return (
            <ProjectTableContentItem
              projectConfig={projectConfig}
              index={index}
              onRemove={this.handleContentRemoved(projectConfig, index)}
              onUpdateProjectConfig={this.handleUpdateProjectConfig}
            />
          )
      }
    })
  }

  saveLayout(e) {
    let body = JSON.parse(JSON.stringify(this.props.body))
    let widget = body[this.props.index]

    if (!widget.tableLayout) {
      widget.tableLayout = e.target.value
    } else {
      widget.tableLayout = e.target.value
    }
    this.props.bodyTemplate(body)
    this.props.templateUpdateError()
  }

  saveOrderBy = (e, type) => {
    const body = JSON.parse(JSON.stringify(this.props.body))
    const widget = body[this.props.index]
    widget.projectConfig = widget.projectConfig || {}
    widget.projectConfig.filter = widget.projectConfig.filter || {}
    widget.projectConfig.filter = {
      ...widget.projectConfig.filter,
      [type]: e.target.value
    }
    this.props.bodyTemplate(body)
    this.props.templateUpdateError()
  }

  selectMeasureOrderFormat = (array, type) => {
    const { index, body } = this.props
    const card = body[index]
    // find the subFields field
    let selectValue = 'select'
    if (
      card &&
      card.projectConfig &&
      card.projectConfig.filter &&
      card.projectConfig.filter[type]
    ) {
      selectValue = card.projectConfig.filter[type]
    }
    return (
      <select value={selectValue} onChange={e => this.saveOrderBy(e, type)}>
        <option disabled value='select'>
          Select Order
        </option>
        {array.map((item, i) => {
          return (
            <option key={i} value={item.value}>
              {item.name}{' '}
            </option>
          )
        })}
      </select>
    )
  }

  getAllSelectedFields = () => {
    const projectConfig = this.props.body[this.props.index].projectConfig
    let allFields = ['name']
    projectConfig?.content &&
      projectConfig.content.forEach(item => {
        if (
          item.key === 'projectDesignTable' ||
          item.key === 'businessCaseTable'
        ) {
          allFields = allFields.concat(item.fields || [])
        }
      })
    allFields = uniq(allFields)
    return [
      ...DETAILS_SECTION(this.props.organizationView._id).sections,
      ...DESIGN_SECTION.sections,
      ...INCENTIVE_SECTION.sections,
      ...FINANCIAL_MODELING_SECTION.sections,
      ...ANALYSIS_RESULT_SECTION.sections,
      ...LOCATION_SECTION.sections
    ].filter(item => allFields.indexOf(item.value) > -1)
  }

  renderMeasureOrder = () => {
    const widget = this.props.body[this.props.index]
    const selectedFilter = widget.projectConfig?.filter
    const orderByFields = this.getAllSelectedFields()
    return (
      <div>
        <h3>Order Format</h3>
        <div className={styles.selectContainer}>
          {this.selectMeasureOrderFormat(orderFormatList, 'orderFormat')}
        </div>
        {selectedFilter.orderFormat &&
          (selectedFilter.orderFormat === 'categorization' ? (
            <ProjectOrdering
              index={this.props.index}
              filteringFields={this.props.filteringFields}
            />
          ) : (
            <>
              {orderByFields.length > 0 && (
                <>
                  <h3>Order By</h3>
                  <div className={styles.selectContainer}>
                    {this.selectMeasureOrderFormat(orderByFields, 'orderBy')}
                  </div>
                </>
              )}
              <h3>Order</h3>
              <div className={styles.selectContainer}>
                {this.selectMeasureOrderFormat(orderFields, 'order')}
              </div>
            </>
          ))}
      </div>
    )
  }

  handleToggleAddContent = () => {
    this.setState({ showAddContent: !this.state.showAddContent })
  }

  handleClickAddContent = (projectConfig, key) => () => {
    const maxOrder = projectConfig.content?.reduce((acc, item) => {
      if (item.key?.toLowerCase().includes('table')) {
        acc += 1
      }
      return acc
    }, 0)
    const newContent = { key }
    const isTable = key === MEASURE_CONTENT_OPTIONS.TABLE
    if (isTable) {
      const order = maxOrder + 1
      newContent.order = order
      newContent.config = {
        includeTitle: true,
        order: 'ascending',
        includeTotal: 'include',
        allowEmptyFields: 'show',
        title: `Table ${order}`
      }
    }
    projectConfig.content.push(newContent)

    this.props.bodyTemplate(this.props.body)
    this.props.templateUpdateError()
  }

  renderAddContentButton = projectConfig => {
    return (
      <div
        className={classNames(
          this.state.showAddContent
            ? portfolioStyles.extrasShow
            : portfolioStyles.extrasHide
        )}
      >
        <button
          className={classNames(
            portfolioStyles.button,
            portfolioStyles.buttonPrimary
          )}
          onClick={this.handleToggleAddContent}
        >
          <i className='material-icons'>add</i>
        </button>

        <div
          className={classNames(
            portfolioStyles.extrasDropdown,
            portfolioStyles.extrasDropdownRight
          )}
        >
          {getAvailableContentList(projectConfig.content).map(
            ({ label, value }) => (
              <div
                className={portfolioStyles.extrasLink}
                onClick={() => {
                  this.handleClickAddContent(projectConfig, value)()
                  this.handleToggleAddContent()
                }}
              >
                <i className='material-icons'>add</i>
                {label}
              </div>
            )
          )}
        </div>
      </div>
    )
  }

  render() {
    const { index, body } = this.props
    const projectConfig = body[index].projectConfig
    return (
      <div>
        <div className={styles.orderDescription}>
          <div className={styles.content}>
            <div className={styles.contentHeaderBar}>
              <h2>Contents</h2>
              {this.renderAddContentButton(projectConfig)}
            </div>
            <div className={styles.contentDiv}>
              {this.renderContentList(projectConfig)}
            </div>
          </div>
        </div>
        {projectConfig.projectGrouping === 'individual' &&
          this.renderMeasureOrder()}
      </div>
    )
  }
}

const mapDispatchToProps = {
  bodyTemplate,
  templateUpdateError
}

const mapStateToProps = state => ({
  body: state.template.templateViewBody || [],
  organizationView: state.organization.organizationView || {}
})

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default withConnect(ProjectContent)
