export const SNAPSHOT_TYPE = Object.freeze({
  Current: 'existing',
  Proposed: 'proposed'
})

export const SNAPSHOT_FUEL = Object.freeze({
  Energy: 'energy',
  Electricity: 'electric',
  NaturalGas: 'gas',
  Water: 'water',
  Demand: 'demand'
})

export const FILTERS = Object.freeze({
  Measure: 'measure',
  Type: 'type',
  Category: 'category',
  Technology: 'technology',
  Application: 'application',
  Priority: 'priority'
})

export const FORMAT = Object.freeze({
  BulletedList: 'bulletedList',
  FullDetails: 'fullDetails',
  SummaryTable: 'summaryTable',
  EndUseTable: 'endUseTable',
  EnergyTable: 'energyTable',
  Card: 'card'
})

export const GROUPING = Object.freeze({
  Individual: 'individual',
  GroupProject: 'groupProject',
  GroupProjectLocation: 'groupProjectLocation'
})
