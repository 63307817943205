import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './DeleteConfirmationModal.scss'

export class ChangeCategoryConfirmation extends React.Component {
  static propTypes = {
    confirmationFunction: PropTypes.func,
    onClose: PropTypes.func,
    show: PropTypes.bool
  }

  render() {
    const { show } = this.props

    return (
      <div
        className={classNames(
          styles.modal,
          styles.templatesModal,
          !show ? styles.hidden : '',
          styles.maxZIndex
        )}
      >
        <div
          className={classNames(styles.modalOuter, styles.modalOuterSmall)}
          style={{ top: 'unset !important' }}
        >
          <div className={styles.modalInner}>
            <div className={styles.templates}>
              <div className={styles.templatesHeading}>
                <h2>Change Equipment Categorization?</h2>
                <div
                  className={styles.modalClose}
                  onClick={() => this.props.onClose()}
                >
                  <i className='material-icons'>close</i>
                </div>
              </div>
              <div className={styles.templatesInner}>
                <p>
                  Changing the categorization will delete all nameplate and
                  configuration data in the equipment. Are you sure?
                </p>
              </div>
              <div className={styles.templatesButtons}>
                <button
                  type='button'
                  className={classNames(styles.button, styles.buttonDelete)}
                  onClick={event => {
                    event.stopPropagation()
                    this.props.onClose()
                  }}
                >
                  NO
                </button>
                <button
                  type='button'
                  className={classNames(styles.button, styles.buttonPrimary)}
                  onClick={event => {
                    event.stopPropagation()
                    this.props.confirmationFunction()
                    this.props.onClose()
                  }}
                  data-test='delete-confirmation'
                >
                  YES
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ChangeCategoryConfirmation
