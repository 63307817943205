import gql from 'graphql-tag'
import { useCallback, useState, useEffect } from 'react'
import { client as apolloClient } from 'utils/ApolloClient'

export const fetchFeatureFlags = async () => {
  const { data } = await apolloClient.query({
    query: gql`
      {
        enabledFeatures {
          name
        }
      }
    `
  })
  return data
}

const useFeatureFlags = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [featureFlags, setFeatureFlags] = useState()

  useEffect(() => {
    fetchFeatureFlags()
      .then(data => {
        setFeatureFlags(data)
      })
      .catch(err => {
        throw err
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  const checkFeatureFlag = useCallback(
    featureFlagName =>
      featureFlags?.enabledFeatures.some(
        feature => feature.name === featureFlagName
      ) || false,
    [featureFlags]
  )

  return {
    featureFlags,
    enabledFeatures: featureFlags?.enabledFeatures,
    isLoading,
    checkFeatureFlag
  }
}

export default useFeatureFlags
