import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import projectFilters from 'static/project-filters.json'
import { getMeasuresTypeFilters } from 'utils/Utils'
import Categorization from 'components/Categorization'
import ProjectCardOrdering from './ProjectCardOrdering'
import styles from './Projects.scss'
import {
  bodyTemplate,
  templateUpdateError
} from 'routes/Template/modules/template'
import { FILTERS, FORMAT, GROUPING } from 'static/dictionaries/project'
import ProjectOrdering from './ProjectOrdering'
import { measurePriorities } from 'static/projects-proposals-measures'

const ABODE_ORG_ID = '67216a61b8a1820016383387'

const isAbodeOrg = orgId => orgId === ABODE_ORG_ID

const initialFilteringFields = {
  measure: {
    label: 'All Measures or From a Proposal',
    open: false,
    data: ['All Measures', 'From a Proposal'],
    isFixed: true
  },
  type: {
    label: 'Filter by Type(s)',
    open: false,
    data: [
      'Energy Efficiency Measure (EEM)',
      'Generation Measure',
      'O&M Measure',
      'RCx Measure (RCM)'
    ]
  },
  category: { open: false, data: [] },
  application: { open: false, data: [] },
  technology: { open: false, data: [] }
}

function ProjectFiltering({ index }) {
  const [filteringFields, setFilteringFields] = useState(initialFilteringFields)
  const [application, setApplication] = useState('')
  const [category, setCategory] = useState('')
  const [technology, setTechnology] = useState('')

  const templateViewBody =
    useSelector(state => state.template.templateViewBody) || []
  const user = useSelector(({ login }) => login.user) || {}
  const organizationView =
    useSelector(({ organization }) => organization.organizationView) || {}
  const dispatch = useDispatch()

  useEffect(() => {
    const newFilteringFields = { ...filteringFields }
    newFilteringFields.type = getMeasuresTypeFilters(
      newFilteringFields.type,
      organizationView._id
    )
    newFilteringFields.category = populateCategoryData()
    newFilteringFields.application = populateApplicationData()
    newFilteringFields.technology = populateTechnologyData()
    if (isAbodeOrg(organizationView._id)) {
      newFilteringFields.priority = populatePriorityData()
    }
    setFilteringFields(newFilteringFields)
  }, [])

  const valueExists = (filter, value) => {
    const card = templateViewBody?.[index]
    const filterConfig = card?.projectConfig?.filter?.[filter]
    return !!filterConfig?.some(el => el?.name === value)
  }

  const removeTemplateFilters = (filter, array) => {
    const newBody = JSON.parse(JSON.stringify(templateViewBody))
    const projectConfig = newBody?.[index]?.projectConfig
    if (projectConfig?.filter?.[filter]) {
      projectConfig.filter[filter] = projectConfig.filter[filter].filter(el => {
        return array.includes(el?.name)
      })
    }
    dispatch(bodyTemplate(newBody))
    dispatch(templateUpdateError())
  }

  function populateCategoryData() {
    const categories = {
      open: false,
      data: (Object.keys(projectFilters) || []).filter(item => item !== 'LL87')
    }
    return categories
  }

  function populateApplicationData() {
    let applications = { open: false, data: [] }
    Object.keys(projectFilters).map(category => {
      if (valueExists(FILTERS.Category, category)) {
        Object.keys(projectFilters[category]).map(application => {
          applications.data.push(application)
        })
      }
    })
    removeTemplateFilters(FILTERS.Application, applications.data)
    return applications
  }

  function populateTechnologyData() {
    let technologies = { open: false, data: [] }
    Object.keys(projectFilters).map(category => {
      if (valueExists(FILTERS.Category, category)) {
        Object.keys(projectFilters[category]).map(application => {
          if (valueExists(FILTERS.Application, application)) {
            technologies.data = technologies.data.concat(
              projectFilters[category][application]
            )
          }
        })
      }
    })
    removeTemplateFilters(FILTERS.Technology, technologies.data)
    return technologies
  }

  function populatePriorityData() {
    return {
      label: 'Filter by Priority',
      open: false,
      data: measurePriorities.filter(item => item.value)
    }
  }

  const populateFilterOptions = filter => {
    let newFilteringFields = { ...filteringFields }
    if (filter === FILTERS.Category) {
      newFilteringFields.application = populateApplicationData()
      newFilteringFields.technology = populateTechnologyData()
    } else if (filter === FILTERS.Application) {
      newFilteringFields.technology = populateTechnologyData()
    }
    setFilteringFields(newFilteringFields)
  }

  const addFilterOption = (option, filter) => {
    const newBody = JSON.parse(JSON.stringify(templateViewBody))
    const card = newBody?.[index]
    if (filter === FILTERS.Measure) {
      card.projectConfig.type =
        option === 'All Measures' ? 'measure' : 'proposal'
    } else {
      if (!card?.projectConfig?.filter?.[filter]) {
        card.projectConfig.filter[filter] = []
      }
      if (filter === FILTERS.Category || filter === FILTERS.Application) {
        if (card?.projectConfig?.format === FORMAT.FullDetails) {
          card.projectConfig = {
            ...card.projectConfig,
            styles: {
              chs: 'h1',
              phs: 'h2',
              layout: 'oneColumn'
            }
          }
        }
        if (card.projectConfig.filter[filter].some(el => el.name === option)) {
          return
        }
        card.projectConfig.filter[filter].push({
          name: option,
          description: '',
          order: card.projectConfig.filter[filter].length + 1
        })
      } else {
        const filterData =
          typeof option == 'object'
            ? { name: option.label, value: option.value }
            : { name: option }
        card.projectConfig.filter[filter].push(filterData)
      }
    }
    openDropdown(filter, 'close')
    populateFilterOptions(filter)
    dispatch(bodyTemplate(newBody))
    dispatch(templateUpdateError())
  }

  const openDropdown = (option, override) => {
    let newFilteringFields = { ...filteringFields }
    Object.keys(newFilteringFields).map(filter => {
      if (override === 'close') {
        newFilteringFields[filter].open = false
        return
      }
      // if you're clicking on a drop down that is already open
      if (filter === option && newFilteringFields[filter].open) {
        newFilteringFields[filter].open = false
        // else, just toggle open it and close all others
      } else {
        newFilteringFields[filter].open = filter === option
      }
    })
    setFilteringFields(newFilteringFields)
  }

  const removeFilterOption = (option, filter) => {
    let newBody = JSON.parse(JSON.stringify(templateViewBody))
    let card = newBody[index]
    card.projectConfig.filter[filter] = card.projectConfig.filter[
      filter
    ].filter(a => a.name !== option)
    // close the drop down when you remove an option
    openDropdown(filter, 'close')
    // reset order when you remove an option
    card.projectConfig.filter[filter].forEach(
      (option, index) => (option.order = index + 1)
    )
    // remove items from the filtering lists, if necessary
    populateFilterOptions(filter)
    dispatch(bodyTemplate(newBody))
    dispatch(templateUpdateError())
  }

  const alphabetizeFilters = (a, b) => {
    return a.name > b.name ? 1 : -1
  }

  const projectConfig = templateViewBody?.[index]?.projectConfig
  const showProjectOrdering =
    projectConfig &&
    projectConfig.format !== FORMAT.SummaryTable &&
    projectConfig.format !== FORMAT.Card &&
    projectConfig.format === FORMAT.BulletedList &&
    projectConfig.projectGrouping !== GROUPING.Individual &&
    (projectConfig.filter?.category?.length > 0 ||
      projectConfig.filter?.application?.length > 0)
  const showConfiguration = projectConfig?.format === FORMAT.FullDetails

  const showProjectCardOrdering =
    projectConfig?.format === FORMAT.Card &&
    (projectConfig?.filter?.category?.length > 0 ||
      projectConfig?.filter?.application?.length > 0)

  return (
    <div className={styles.filter}>
      <h3>Measures To Include</h3>
      <div className={styles.projectFilterContainer}>
        <div className={styles.projectFilterDropdown}>
          {Object.keys(filteringFields).map(filter => {
            if (
              filter === FILTERS.Application ||
              filter === FILTERS.Technology
            ) {
              return null
            }
            if (filter === FILTERS.Category) {
              return (
                <div className={styles.categorizationContainerInput}>
                  <Categorization
                    category={category}
                    application={application}
                    technology={technology}
                    containerStyle={styles.containerStyle}
                    showMeasureCategorization
                    handleCategory={val => {
                      addFilterOption(val, FILTERS.Category)
                      setCategory(val)
                    }}
                    handleApplication={val => {
                      addFilterOption(val, FILTERS.Application)
                      setApplication(val)
                    }}
                    handleTechnology={val => {
                      addFilterOption(val, FILTERS.Technology)
                      setTechnology(val)
                    }}
                    hideAllOption
                    hideEmptyMenu
                    showOnlyCategory
                  />
                </div>
              )
            }
            const field = filteringFields[filter]
            return (
              <div key={filter} className={styles.dropdownSingle}>
                {field?.data?.length > 0 && (
                  <div
                    className={styles.dropdownSelect}
                    onClick={e => openDropdown(filter)}
                  >
                    <p>{field?.label}</p>
                    <span>
                      <i className='material-icons'>
                        {field.open ? 'arrow_drop_up' : 'arrow_drop_down'}
                      </i>
                    </span>
                  </div>
                )}
                {field.open && field.data?.length > 0 && (
                  <div className={styles.dropdownOptions}>
                    {field.data.map(option => {
                      const optionValue =
                        typeof option === 'object' ? option.value : option
                      const optionName =
                        typeof option === 'object' ? option.label : option
                      // don't show anything if the option is already in the filter list
                      if (
                        projectConfig?.filter?.[filter]?.filter(
                          e => e.name === optionName
                        )?.length > 0
                      ) {
                        return
                      }
                      if (
                        user?.products?.buildeeNYC !== 'access' &&
                        optionValue === 'NYC LL87 RCM'
                      ) {
                        return
                      }
                      return (
                        <p
                          key={optionValue}
                          className='filter-option'
                          onClick={() => addFilterOption(option, filter)}
                        >
                          {optionName}
                        </p>
                      )
                    })}
                  </div>
                )}
              </div>
            )
          })}
        </div>
        <div className={styles.categorizationContainer}>
          <div className={styles.categorizationContainerSelected}>
            {projectConfig?.type && (
              <div className={styles.dropdownDisplay}>
                <p>
                  {projectConfig?.type === 'measure'
                    ? 'All Measures'
                    : 'From a Proposal'}
                </p>
              </div>
            )}
            {projectConfig?.filter?.priority?.length > 0 && (
              <>
                {projectConfig?.filter?.priority.map(option => (
                  <div className={styles.dropdownDisplay}>
                    <p>{option?.name}</p>
                  </div>
                ))}
              </>
            )}
            {[FILTERS.Type, FILTERS.Category].map(filter => {
              return (
                <>
                  {projectConfig?.filter?.[filter]?.length > 0 && (
                    <>
                      {projectConfig.filter[filter]
                        .sort(alphabetizeFilters)
                        .map(option => {
                          return (
                            <p key={option.name}>
                              {option.name}
                              <span
                                className='filter-option'
                                onClick={() => {
                                  removeFilterOption(option.name, filter)
                                }}
                              >
                                <i className='material-icons filter-option'>
                                  close
                                </i>
                              </span>
                            </p>
                          )
                        })}
                    </>
                  )}
                </>
              )
            })}
          </div>
        </div>
      </div>
      {showProjectOrdering && (
        <ProjectOrdering index={index} filteringFields={filteringFields} />
      )}
      {showProjectCardOrdering && (
        <ProjectCardOrdering index={index} filteringFields={filteringFields} />
      )}
    </div>
  )
}

ProjectFiltering.propTypes = {
  index: PropTypes.number.isRequired
}

export default ProjectFiltering
