import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { withApollo } from 'react-apollo'
import { connect } from 'react-redux'
import { push } from 'react-router-redux'
import { withAuth0 } from '@auth0/auth0-react'
import styles from './LoggedInHeader.scss'
import { logout } from 'routes/Login/modules/login'
import Logo from '../../components/Svg'
import {
  getOrganizations,
  getOrganization,
  toggleManageAllOrgs
} from 'routes/Organization/modules/organization'
import { downloadPowerBI } from 'routes/Portfolio/modules/portfolio'
import { updatePortfolioTab } from 'routes/Portfolio/modules/portfolio'
import { updateUserProducts } from 'routes/Profile/modules/profile'
import { clearDocuSignStore } from 'routes/DocuSign/modules/docuSign'
import { AppDropdown, UserDropdown, OrganizationDropdown } from './'
import { detectMobileTouch, isProdEnv } from 'utils/Utils'
import UserFeature from 'utils/Feature/UserFeature'
import UserFeatures from 'utils/Feature/UserFeatures'
import { PORTFOLIO_TABS } from 'utils/PortfolioOptions'
import useOutsideClick from 'utils/hooks/useOutsideClick'

const defaultTheme = {
  primaryColor: '#48A272',
  secondaryColor: '#17253F',
  linkColor: '#54BE85',
  headerTextColor: '#FFFFFF',
  primaryTextColor: '#17253F'
}

const applyBrandTheme = theme => {
  const {
    primaryColor,
    secondaryColor,
    linkColor,
    headerTextColor,
    primaryTextColor
  } = theme
  document.documentElement.style.setProperty('--primary-color', primaryColor)
  document.documentElement.style.setProperty(
    '--secondary-color',
    secondaryColor
  )
  document.documentElement.style.setProperty(
    '--header-text-color',
    headerTextColor
  )
  document.documentElement.style.setProperty('--link-color', linkColor)
  document.documentElement.style.setProperty(
    '--primary-text-color',
    primaryTextColor
  )
}

const LoggedInHeader = props => {
  const prevProps = useRef(props)
  const node = useRef(null)
  const [activeTab, setActiveTab] = useState('')
  const [openMobile, setOpenMobile] = useState(false)

  const { user } = props
  const userValidations =
    user &&
    ((user.firebaseRefs &&
      user.firebaseRefs.orgId &&
      user.firebaseRefs.userId) ||
      (user.products &&
        user.products.buildeeNYC &&
        (user.products.buildeeNYC === 'show' ||
          user.products.buildeeNYC === 'access')))

  const setupIntercomTrack = () => {
    if (isProdEnv(process.env.DOMAIN_ENV)) {
      window.Intercom('boot', {
        app_id: 'gqgtysua',
        name: user.name,
        email: user.email,
        created_at: user.created,
        custom_launcher_selector: '#IntercomHelp'
      })
    }
  }

  const handleOpenMobileMenu = () => {
    setOpenMobile(!openMobile)
  }

  const handleCloseMobileMenu = () => {
    setOpenMobile(false)
  }

  const handleClickLogo = (event, enabled) => {
    event.preventDefault()
    if (enabled) {
      props.push('/organization/' + props.organizationView._id + '/portfolio')
      setActiveTab('portfolio')
    } else {
      props.push('/organization/' + props.organizationView._id + '/building')
    }
    setOpenMobile(false)
  }

  const handleClickPortfolio = (event, info = 'dashboard') => {
    event.preventDefault()
    props.push(
      '/organization/' + props.organizationView._id + '/portfolio/' + `${info}`
    )
    let activeTab = 'portfolio'
    if (
      ['building', 'measure', 'project', 'scenario', 'proposal'].includes(info)
    ) {
      activeTab = info
    }

    setActiveTab(activeTab)
    setOpenMobile(false)
  }

  const handleClickBuildings = event => {
    event.preventDefault()
    props.push('/organization/' + props.organizationView._id + '/building')
    setActiveTab('buildings')
    setOpenMobile(false)
  }

  const handleClickLibrary = event => {
    event.preventDefault()
    props.push('/organization/' + props.organizationView._id + '/library')

    setActiveTab('library')
    setOpenMobile(false)
  }

  const handleClickTemplates = value => {
    if (value === 'Documents') {
      props.push('/organization/' + props.organizationView._id + '/template')

      setActiveTab('templates')
      setOpenMobile(false)
    } else if (value === 'Spreadsheets') {
      props.push('/spreadsheet/templatelist')

      setActiveTab('spreadsheets')
      setOpenMobile(false)
    }
  }

  const handleActiveTab = () => {
    if (window.location.href.indexOf('building') > -1) {
      setActiveTab('buildings')
    }
    if (window.location.href.indexOf('template') > -1) {
      setActiveTab('templates')
    }
    if (window.location.href.indexOf('spreadsheet') > -1) {
      setActiveTab('spreadsheets')
    }
    if (window.location.href.indexOf('library') > -1) {
      setActiveTab('library')
    }
    if (window.location.href.indexOf('dashboard') > -1) {
      setActiveTab('dashboard')
    }
    if (window.location.href.indexOf('portfolio') > -1) {
      setActiveTab('portfolio')
    }
    if (window.location.href.indexOf('portfolio/project') > -1) {
      setActiveTab('project')
    }
    if (window.location.href.indexOf('portfolio/building') > -1) {
      setActiveTab('building')
    }
    if (window.location.href.indexOf('portfolio/measure') > -1) {
      setActiveTab('measure')
    }
    if (window.location.href.indexOf('portfolio/team') > -1) {
      setActiveTab('team')
    }
    if (window.location.href.indexOf('portfolio/scenario') > -1) {
      setActiveTab('scenario')
    }
    if (window.location.href.indexOf('portfolio/proposal') > -1) {
      setActiveTab('proposal')
    }
    if (window.location.href.indexOf('portfolio/tasks') > -1) {
      setActiveTab('tasks')
    }
  }

  const handleLogout = () => {
    props.auth0.logout({ returnTo: window.location.origin })
    props.logout().then(() => {
      props.client.resetStore()
    })
  }

  useOutsideClick(node, handleCloseMobileMenu)

  useEffect(() => {
    applyBrandTheme(props.theme)
    handleActiveTab()
    setupIntercomTrack()
    prevProps.current = props

    return () => {
      setOpenMobile(false)
      if (isProdEnv(process.env.DOMAIN_ENV)) {
        window.Intercom('shutdown', {
          app_id: 'gqgtysua'
        })
      }
    }
  }, [])

  useEffect(() => {
    console.log(props)
    if (prevProps.current !== props) {
      handleActiveTab()
    }
    if (props.organizationView !== prevProps.current.organizationView) {
      applyBrandTheme(props.theme)
    }

    prevProps.current = props
  }, [props])

  // If the user is in the forgot password flow then do not render the top menu
  if (user.resetPassword) {
    return <span />
  }

  return (
    <UserFeature name='portfolio'>
      {({ enabled: portfolioEnabled }) => (
        <div className={styles.headerNav} id='headerNav'>
          {openMobile && (
            <div
              className={classNames(
                styles.headerNavMobile,
                openMobile ? styles.active : ''
              )}
            >
              <div
                className={classNames(
                  styles.headerNavMobileInner,
                  openMobile ? styles.active : ''
                )}
                ref={node}
              >
                <div
                  className={styles.logo}
                  onClick={e => handleClickLogo(e, portfolioEnabled)}
                >
                  <Logo maxHeight={'home'} theme={props.theme} />
                </div>
                {user.acceptedTerms && (
                  <div className={classNames(styles.orgDropdown, 'menuClick')}>
                    <OrganizationDropdown
                      push={props.push}
                      user={props.user}
                      organizationView={props.organizationView}
                      getOrganizations={props.getOrganizations}
                      manageAllOrgSelected={props.manageAllOrgSelected}
                      getOrganization={props.getOrganization}
                      handleCloseMobileMenu={handleCloseMobileMenu}
                      enabledPortfolio={portfolioEnabled}
                      updatePortfolioTab={props.updatePortfolioTab}
                      clearDocuSignStore={props.clearDocuSignStore}
                      toggleManageAllOrgs={props.toggleManageAllOrgs}
                      canAdmin={props.canAdmin}
                    />
                  </div>
                )}

                {user.acceptedTerms && (
                  <div className={styles.links}>
                    {portfolioEnabled && detectMobileTouch() !== 'mobile' ? (
                      <div
                        className={styles.link}
                        onClick={handleClickPortfolio}
                      >
                        <span>PortFolio</span>
                        <i className='material-icons'>domain</i>
                      </div>
                    ) : (
                      <div
                        className={styles.link}
                        onClick={handleClickBuildings}
                      >
                        <span>Buildings</span>
                        <i className='material-icons'>domain</i>
                      </div>
                    )}

                    <UserFeature name='library'>
                      {({ enabled }) => {
                        if (!enabled) return null
                        return (
                          <div
                            className={styles.link}
                            onClick={handleClickLibrary}
                          >
                            <span>Library</span>
                            <i className='material-icons'>library_books</i>
                          </div>
                        )
                      }}
                    </UserFeature>
                    {detectMobileTouch() === 'desktop' && (
                      <div
                        className={styles.link}
                        onClick={() => handleClickTemplates('Documents')}
                      >
                        <span>Reports</span>
                        <i className='material-icons'>bar_chart</i>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
          <div className={styles.container} data-test='logged-in-header'>
            <div className={styles.headerNavMain}>
              <div
                className={styles.headerNavMobileClick}
                onClick={() => handleOpenMobileMenu()}
              >
                <span />
              </div>
              <div
                className={styles.headerNavLogo}
                onClick={e => handleClickLogo(e, portfolioEnabled)}
              >
                <Logo maxHeight={'twenty'} theme={props.theme} />
              </div>
              {user.acceptedTerms && (
                <div
                  className={classNames(
                    styles.headerNavDesktop,
                    styles.headerNavDesktopShow
                  )}
                >
                  <div className={classNames(styles.menus, styles.orgs)}>
                    <OrganizationDropdown
                      push={props.push}
                      user={props.user}
                      organizationView={props.organizationView}
                      getOrganizations={props.getOrganizations}
                      getOrganization={props.getOrganization}
                      manageAllOrgSelected={props.manageAllOrgSelected}
                      enabledPortfolio={portfolioEnabled}
                      updatePortfolioTab={props.updatePortfolioTab}
                      clearDocuSignStore={props.clearDocuSignStore}
                      toggleManageAllOrgs={props.toggleManageAllOrgs}
                      canAdmin={props.canAdmin}
                    />
                  </div>
                  {!portfolioEnabled || detectMobileTouch() === 'mobile' ? (
                    <div
                      className={classNames(
                        styles.link,
                        activeTab === 'buildings' ? styles.active : ''
                      )}
                      onClick={handleClickBuildings}
                    >
                      Buildings
                    </div>
                  ) : (
                    <UserFeature name='portfolioDashboard'>
                      {({ enabled }) =>
                        // This has to be disabled since the portfolioDashboard flag is default active
                        enabled ? (
                          <div
                            className={classNames(
                              styles.link,
                              activeTab === 'portfolio' ? styles.active : ''
                            )}
                            onClick={event =>
                              handleClickPortfolio(event, 'dashboard')
                            }
                          >
                            Dashboard
                          </div>
                        ) : null
                      }
                    </UserFeature>
                  )}
                  {/* Slice the first 2 elements due their own complex logic from above */}
                  {PORTFOLIO_TABS.slice(2).map(tab => (
                    <UserFeatures
                      featureNames={tab.requiredFeatures}
                      key={tab.name}
                    >
                      {({ enabled }) => {
                        if (!enabled) return null
                        return (
                          <div
                            className={classNames(
                              styles.link,
                              activeTab === tab.route ? styles.active : ''
                            )}
                            onClick={event =>
                              handleClickPortfolio(event, tab.route)
                            }
                          >
                            {tab.name}
                          </div>
                        )
                      }}
                    </UserFeatures>
                  ))}
                </div>
              )}
            </div>

            <div className={styles.headerNavDesktop}>
              {userValidations && (
                <div className={styles.menus}>
                  <AppDropdown
                    push={props.push}
                    products={props.user.products}
                    updateUserProducts={props.updateUserProducts}
                  />
                </div>
              )}
              {!!isProdEnv(process.env.DOMAIN_ENV) && (
                <div
                  id='IntercomHelp'
                  className={classNames(styles.helpContainer, styles.menus)}
                >
                  <i id='helpIcon' className='material-icons'>
                    help_outline
                  </i>
                </div>
              )}
              <div className={styles.menus}>
                <UserDropdown
                  push={props.push}
                  logout={handleLogout}
                  user={props.user}
                  downloadPowerBI={props.downloadPowerBI}
                  organizationId={props.organizationView?._id}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </UserFeature>
  )
}

LoggedInHeader.propTypes = {
  auth0: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  organizationView: PropTypes.object.isRequired,
  push: PropTypes.func.isRequired,
  getOrganizations: PropTypes.func.isRequired,
  getOrganization: PropTypes.func.isRequired,
  updateUserProducts: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  updatePortfolioTab: PropTypes.func.isRequired,
  clearDocuSignStore: PropTypes.func.isRequired,
  theme: PropTypes.object,
  manageAllOrgSelected: PropTypes.bool,
  toggleManageAllOrgs: PropTypes.func,
  canAdmin: PropTypes.bool,
  downloadPowerBI: PropTypes.func
}

const mapDispatchToProps = {
  getOrganizations,
  getOrganization,
  updateUserProducts,
  push,
  updatePortfolioTab,
  logout,
  clearDocuSignStore,
  toggleManageAllOrgs,
  downloadPowerBI
}

const mapStateToProps = ({ login, organization }) => {
  const currentOrg = organization.organizationList.find(org => {
    if (organization.organizationView) {
      return org._id === organization.organizationView._id
    }
  })
  return {
    user: login.user || {},
    organizationView: organization.organizationView || {},
    manageAllOrgSelected: organization.manageAllOrgSelected || false,
    theme: (currentOrg && currentOrg.theme) || defaultTheme,
    canAdmin: currentOrg?.users?.some(
      user =>
        user.userId === login.user._id &&
        (user.userRole === 'admin' || user.userRole === 'owner')
    )
  }
}

export default withApollo(
  connect(mapStateToProps, mapDispatchToProps)(withAuth0(LoggedInHeader))
)
